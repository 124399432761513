import React, { useEffect, useState } from 'react';
import '../stylesheets/FourSquareImage.css';

const FourSquareImage = ({ image1, image2, image3, image4 }) => {
  const [selectedText, setSelectedText] = useState('');

  useEffect(() => {
    setSelectedText(
      <div className="text-container">
        <h4>Expertise & Experience</h4>
        <div className="text-left">
          <li>15+ years in private and public sectors</li>
          <li>Certified veteran-owned business specializing in project management, IT solutions, and strategic advisory</li>
          <li>Offering expertise in grocery retail, process optimization, cloud computing, and B2G (Business to Government) solutions</li>
        </div>
        <h4>Global Reach & International Expertise</h4>
        <div className="text-left">
          <li>Authorized to operate without restrictions in the US and Europe</li>
          <li>In-depth knowledge of the German market, including business practices, regulations, and language</li>
          <li>Proven success in overseas operations, including the launch and maintenance of international facilities, with strong support for international expansion, digital transformation, and cross-border operations</li>
        </div>
        <h4>Client-Focused Solutions</h4>
        <div className="text-left">
          <li>Deliver tailored solutions to drive innovation, efficiency, and sustained growth</li>
          <li>Focused on delivering high-impact projects and operational support both nationally and internationally</li>
        </div>
      </div>
    );
  }, []);

  const handleClick = (text) => {
    setSelectedText(text);
  };

  return (
    <div className="container">
      <div className="left">
        <img src={image1} alt="Image 1" className="image" onClick={() => handleClick(`We specialize in delivering web and mobile applications for Android, iOS, and cross-platform, creating customized cloud architectures, and implementing state-of-the-art cyber-security methods to protect client systems. Our services include native and cross-platform application development, designing and implementing cyber-security best practices to ensure confidentiality, integrity, and availability, assisting with all stages of business digitalization from initial setup to full cloud hosting, and conducting code analysis to optimize software for security and energy efficiency.`)} />
        <img src={image2} alt="Image 2" className="image" onClick={() => handleClick(`As a certified Project Management Professional (PMP) with extensive experience in the global grocery retail sector, I offer comprehensive project management services that streamline operations, enhance efficiency, and drive growth. My expertise covers the entire project lifecycle, from strategic planning and consultation to execution, monitoring, and successful project closure. Our services include tailored strategies, detailed project plans, coordinated implementation, continuous oversight, and comprehensive reviews. With deep industry expertise, PMP certification, proficiency in various methodologies, and a proven track record, we are committed to delivering value and achieving client satisfaction through customized solutions. Partner with us to transform your project management processes and achieve your business objectives with confidence.`)} />
        <img src={image3} alt="Image 3" className="image" onClick={() => handleClick(
          <div>
              <h5>Browning Projects performs the following work with public sector clients</h5>
              <h4>NAICS codes</h4>
              <ul>541611: Administrative Management and General Management Consulting Services</ul>
              <ul>541511: Custom Computer Programming Services</ul>
              <ul>541614: Process, Physical Distribution, and Logistics Consulting Services</ul>
              <ul>541618: Other Management Consulting Services</ul>
              <ul>541690: Other Scientific and Technical Consulting Services</ul>
              <ul>611430: Professional and Management Development Training</ul>
              <ul>541990: All Other Professional, Scientific, and Technical Services</ul>
              <ul>541350: Building Inspection Services</ul>
              <ul>H399: Inspection- Miscellaneous</ul>
            </div>)} />
        <img src={image4} alt="Image 4" className="image" onClick={() => handleClick(`At Browning Projects, we offer comprehensive consulting services to drive your business forward, specializing in project management, process engineering and optimization, IT solutions, cloud services, and cybersecurity. Our cloud services include strategy and planning, migration, management, and security to help you leverage cloud computing effectively. In cybersecurity, we provide security assessments, threat detection and response, compliance and governance, and staff training. We also excel in optimizing processes for global grocery retail operations, covering supply chain optimization, inventory management, store operations improvement, logistics and distribution, direct store delivery, data analytics, AI-driven decision-making, third-party vendor management, and sustainability initiatives. Our goal is to enhance efficiency, reduce costs, and improve customer satisfaction through tailored solutions.`)} />
      </div>
      <div className="right">
        <p>{selectedText}</p>
      </div>
    </div>
  );
};

export default FourSquareImage;