import React, { useState } from "react";
import "../stylesheets/SmallScreenComponent.css";

const SmallScreenComponent = ({ image1, image2, image3, image4 }) => {
  const [clickedImage, setClickedImage] = useState(null);

  const handleClick = (imageIndex) => {
    setClickedImage(imageIndex);
  };

  const handleClose = () => {
    setClickedImage(null);
  };

  return (
    <div className="small-screen-container">
      <div className="image-grid">
        {clickedImage === 1 ? (
          <div className="text-overlay" onClick={handleClose}>
            <p>
              We specialize in delivering web and mobile applications for
              Android, iOS, and cross-platform, creating customized cloud
              architectures, and implementing state-of-the-art cyber-security
              methods to protect client systems. Our services include native and
              cross-platform application development, designing and implementing
              cyber-security best practices to ensure confidentiality,
              integrity, and availability, assisting with all stages of business
              digitalization from initial setup to full cloud hosting, and
              conducting code analysis to optimize software for security and
              energy efficiency.
            </p>
          </div>
        ) : (
          <img
            src={image1}
            alt="Image 1"
            className="grid-image"
            onClick={() => handleClick(1)}
          />
        )}
        {clickedImage === 2 ? (
          <div className="text-overlay" onClick={handleClose}>
            <p>
              As a certified Project Management Professional (PMP) with
              extensive experience in the global grocery retail sector, I offer
              comprehensive project management services that streamline
              operations, enhance efficiency, and drive growth. My expertise
              covers the entire project lifecycle, from strategic planning and
              consultation to execution, monitoring, and successful project
              closure. Our services include tailored strategies, detailed
              project plans, coordinated implementation, continuous oversight,
              and comprehensive reviews. With deep industry expertise, PMP
              certification, proficiency in various methodologies, and a proven
              track record, we are committed to delivering value and achieving
              client satisfaction through customized solutions. Partner with us
              to transform your project management processes and achieve your
              business objectives with confidence.
            </p>
          </div>
        ) : (
          <img
            src={image2}
            alt="Image 2"
            className="grid-image"
            onClick={() => handleClick(2)}
          />
        )}
        {clickedImage === 3 ? (
          <div className="text-overlay" onClick={handleClose}>
              <h5>Browning Projects performs the following work with public sector clients</h5>
              <h4>NAICS codes</h4>
              <ul>541611: Administrative Management and General Management Consulting Services</ul>
              <ul>541511: Custom Computer Programming Services</ul>
              <ul>541614: Process, Physical Distribution, and Logistics Consulting Services</ul>
              <ul>541618: Other Management Consulting Services</ul>
              <ul>541690: Other Scientific and Technical Consulting Services</ul>
              <ul>611430: Professional and Management Development Training</ul>
              <ul>541990: All Other Professional, Scientific, and Technical Services</ul>
              <ul>541350: Building Inspection Services</ul>
              <ul>H399: Inspection- Miscellaneous</ul>
            </div>
        ) : (
          <img
            src={image3} 
            alt="Image 3"
            className="grid-image"
            onClick={() => handleClick(3)}
          />
        )}
        {clickedImage === 4 ? (
          <div className="text-overlay" onClick={handleClose}>
            <p>
              At Browning Projects, we offer comprehensive consulting services
              to drive your business forward, specializing in project
              management, process engineering and optimization, IT solutions,
              cloud services, and cybersecurity. Our cloud services include
              strategy and planning, migration, management, and security to
              help you leverage cloud computing effectively. In cybersecurity,
              we provide security assessments, threat detection and response,
              compliance and governance, and staff training. We also excel in
              optimizing processes for global grocery retail operations,
              covering supply chain optimization, inventory management, store
              operations improvement, logistics and distribution, direct store
              delivery, data analytics, AI-driven decision-making, third-party
              vendor management, and sustainability initiatives. Our goal is to
              enhance efficiency, reduce costs, and improve customer
              satisfaction through tailored solutions.
            </p>
          </div>
        ) : (
          <img
            src={image4}
            alt="Image 4"
            className="grid-image"
            onClick={() => handleClick(4)}
          />
        )}
      </div>
    </div>
  );
};

export default SmallScreenComponent;