import React, { useState } from "react";
import "../stylesheets/SplitScreenRight.css";

const SplitScreenRight = ({ imageUrl, titles, texts, showButton }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="split-screen-right">
      <div className="left-pane-ssr">
        {titles.map((title, index) => (
          <div className="text-block" key={index}>
            <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
              {React.isValidElement(title) ? title : <span>{title}</span>}
            </h1>
            {showButton && !expanded ? (
              <div>
                {texts[index].props.children.map((child, i) => {
                  if (i === 0 || child.type === "h4") return child;
                  if (i === 1 && child.type === "p") return child;
                  return null;
                })}
              </div>
            ) : (
              <div>{texts[index]}</div>
            )}
          </div>
        ))}
        {showButton && (
          <button
            onClick={handleExpandClick}
            style={{
              background: "none",
              border: "none",
              color: "darkblue",
              cursor: "pointer",
            }}
            aria-label={expanded ? "Show Less" : "Show More"}
          >
            {expanded ? "Show Less" : "Show More"}
          </button>
        )}
      </div>
      <div style={{ padding: "0" }} className="right-pane">
        <img src={imageUrl} alt="Description" />
      </div>
    </div>
  );
};

export default SplitScreenRight;
